import React from 'react';

import "./ApplicationView.css";

import Banner from '../common/Banner/Banner';
import ContentList from '../common/Content/ContentList';

//content
import AdmissionCriteria from '../../content/AcceptanceCriteria.json';

const ApplicationView = ({
    onBannerClick
}) => {
    //set up application page
    const admissionHeader = AdmissionCriteria.header;
    const admissionCriteriaArray = AdmissionCriteria.body.split(", ");

    return (
        <div className='application-content'>
            <Banner onBannerClick={onBannerClick} labelText={"Resident Application"}/>
            <div className='application-content__lead-paragraph-wrapper'>
                <div className='application-content__lead-paragraph'>
                    <p className='application-content__lead-paragraph-text'>
                      <span className='application-content__lead-paragraph-text-highlight'>
                        Hey Mama,
                      </span>
                      <br/>
                      <br/>
                      We are so proud of you for being here! Hope House is a 12-month residential maternity program specifically designed for first-time expectant mothers. If accepted into Hope House, our commitment is to serve, equip, and empower you, setting you up for success in life and motherhood.
                      <br/>
                      <br/>
                      During your time at Hope House, you will have access to a wide range of services to support you and your baby, including mental health care, financial literacy training, career and job skills development, life-skills training, and educational opportunities, all while living in a beautiful home. You’ll also receive food, clothing, transportation, and resource management support. After your baby is born, our care doesn't stop—we will continue to support you through your postpartum journey and assist with your baby’s needs as we help you transition into the next chapter of your life.
                      <br/>
                      <br/>
                      Please note, we are a non-smoking, drug-free, and alcohol-free program. Unfortunately, we are unable to accept additional children into your care while you stay with us.
                      <br/>
                      <br/>
                        At Hope House, our mission is to walk alongside you with love, encouragement, and support during this important season. Our nurturing environment is here to help you and your baby thrive.
                        <br/>
                        <br/>
                        While we’ll be by your side, it's important to understand that this program will require commitment and hard work from you. Success means fully engaging in the activities, training, and guidance we provide, all aimed at creating a brighter future for both you and your baby.
                        <br/>
                        <br/>
                        We believe in your potential and are excited to see all that you will accomplish. This is a courageous step, and we are here to help you make it the best one for you and your baby!
                        <br/>
                        <br/>
                        <span className='application-content__lead-paragraph-text-highlight'>
                            Ready to take the next step?
                        </span>
                        <br/>
                        Click “Apply” to fill out your application to become a resident. Once your application is submitted, one of our directors will reach out to you within 1-3 business days for a brief initial intake phone interview and to answer any questions you may have.
                        <br/>
                        <br/>
                        <span className='application-content__lead-paragraph-text-highlight'>
                            {admissionHeader}
                        </span>
                        <ul className='application-content__criteria-list'>
                            <li>First-time expectant mothers in the first trimester of pregnancy</li>
                            <li>Age 18-26</li>
                            <li>A strong desire to transform your life for the benefit of yourself and your baby</li>
                            <li>A teachable heart that’s open to receiving guidance, coaching, and encouragement</li>
                            <li>A willingness to embrace personal development, life skills training, and counseling</li>
                            <li>No DCF involvement</li>
                            <li>Drug-free for at least 12 months</li>
                        </ul>
                        We can’t wait to meet you and begin this life-changing journey together!
                        <br/>
                        <br/>
                        <span className='application-content__lead-paragraph-text-highlight'>
                            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdofXVTGk0d1BAzd75Yj6AfCARHSpBzBcFuV9UMqWbLfvfoUQ/viewform?usp=sf_link" style={{textDecoration: 'none'}}>
                                <span className='application-content__lead-paragraph-text-highlight'>
                                    <strong>
                                        CLICK HERE TO APPLY!
                                    </strong>
                                </span>
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ApplicationView