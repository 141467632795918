import Header from "./components/Header/Header";
import Home from "./components/Home/Home"

//stock imports
import React from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import MissionView from "./components/Pages/MissionView";
import ProgramView from "./components/Pages/ProgramView";
import GivingView from "./components/Pages/GivingView";
import VolunteerView from "./components/Pages/VolunteerView";
import WaiverView from "./components/Pages/WaverView";
import ApplicationView from "./components/Pages/ApplicationView";
import GalaPage from "./components/Pages/special-pages/GalaPage";
import EventsPage from "./components/Pages/Events/EventsPage";
import EventsRegistrationPage from "./components/Pages/Events/EventsRegistrationPage";
import { formatSingleDateStringForUrl } from "./components/utilities/DateFormatUtility";

//content
import events from "./content/temp-content/events/events.json"


function App() {
  const GIVING_ROUTE = '/giving';
  const HOME_ROUTE = '/';
  const EVENTS_ROUTE = '/events';
  const VOLUNTEER_ROUTE = '/volunteer';
  const ABOUT_US_ROUTE = '/about-us';
  const APPLICATION_ROUTE ='/application';
  //states
  const location = useLocation();

  const getDynamicEevnts = () => {
    //make event date object list
    const eventRoutes = events.map(eventItem => {
      const eventStart = new Date(eventItem.eventStart);
      const eventEnd = new Date(eventItem.eventEnd);
      if (new Date() < eventEnd) {
        //if event hasn't passed
        return (
          <React.Fragment>
            <Route
              exact
              path={`/${formatSingleDateStringForUrl(eventStart)}`}
              element={
                <EventsRegistrationPage
                  registrationActive={eventItem.registration_active}
                  eventDate={eventStart}
                />
              }
            />
          </React.Fragment>
        );
      }
    })
    return eventRoutes
  }

  return (
    <React.Fragment>
      <Header />
        <main>
            {/* <Router> */}
            <Routes>
              <Route exact path={HOME_ROUTE} element={<Home/>}/>
              {/* <Route path="/nightofhope" element={<GalaPage />} /> */}
              <Route exact path={EVENTS_ROUTE} element={<EventsPage />}/>
              <Route exact path={ABOUT_US_ROUTE} element={<MissionView  />}/>
              <Route exact path={VOLUNTEER_ROUTE} element={<VolunteerView  />}/>
              <Route exact path={GIVING_ROUTE} element={<GivingView  />}/>
              <Route exact path={APPLICATION_ROUTE} element={<ApplicationView  />}/>
              <Route exact path="/waiver" element={<WaiverView />}/>
              <Route path="*" element={<Navigate replace to="/" />}/>
              {/** Auto generate routes for events */}
              {getDynamicEevnts(events)}
            </Routes>
            {/* </Router> */}
        </main>
      {location.pathname !== '/nightofhope' && <Footer />}
    </React.Fragment>
  );
}

export default App;
