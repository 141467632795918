import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import "./Header.css";
import DropDown from '../common/DropDown/DropDown';
import MenuIcon from '../../assets/hh-mobile-menu-icon.png';
import smallLogoWhite from "../../assets/hh-web-logo-white.png";
import smallLogoPink from "../../assets/hh-web-logo-pink.png";
import { determineRoute } from '../route-utilities/route-util';

const Header = ({
  //TODO: simplify header navs
  //props
}) => {
  //states
  const [displayDropDown, setDisplayDropDown] = useState(false);
  const [scrollingHeader, setScrollingHeader] = useState(false);

  //handle header selection
  let navigate = useNavigate();
  const navClickHandler = (selectedPage) => {
    navigate(`${determineRoute(selectedPage)}`)
  };

  //SCROLLING HEADER CONFIGURATION

  useEffect(() => {
    //if window is defined
    if (typeof window !== "undefined") {
      //add listener to flip flag
      window.addEventListener("scroll", () => {
        setScrollingHeader(window.scrollY > 0);
      });
    }
  }, []);

  //DROP DOWN CONFIGURATION

  const dropDownClickHandler = () => {
    console.log("button clicked");

    if (displayDropDown) {
      setDisplayDropDown(false);
    } else {
      setDisplayDropDown(true);
    }
  };

  const getInvolvedDropDownOptions = ["Serve Days", "Get Involved"];

  const dropDownMenuOptions = [
    "Home",
    "About Us",
    "Get Involved",
    "Giving",
    "Serve Days",
    "Apply"
  ];

  const dropDownSelectionHandler = (selection) => {
    console.log(`selection: ${selection}`);
    setDisplayDropDown(false);
    switch (selection) {
      case "Home":
        navClickHandler("home");
        break;
      case "About Us":
        navClickHandler("mission");
        break;
      case "Get Involved":
        navClickHandler("volunteer");
        break;
      case "Giving":
        navClickHandler("giving");
        break;
      case "Serve Days":
        navClickHandler("events");
        break;
      case "Apply":
        navClickHandler("apply")
      default:
        console.log("error parsing drop down selection");
    }
  };

  return (
    //conditionally add scrolling header class to nav main class
    <header className={`header-main ${scrollingHeader ? "scrolling" : ""}`}>
      {/** TODO: ADD IMAGE BLOCK FLOATING LEFT */}
      <div className="header-main__logo-container">
        {/** select header logo image based on scrolling attribute */}
        <img
          className="header-main__logo"
          src={scrollingHeader ? smallLogoPink : smallLogoWhite}
        />
      </div>
      <nav className="header-nav">
        {/** hamburger nav bar */}
        <a
          className="header-nav__mobile-menu"
          onClick={() => dropDownClickHandler()}
        >
          {displayDropDown && (
            <DropDown
              toggleDropDown={setDisplayDropDown}
              listItemArray={dropDownMenuOptions}
              selectionHandler={dropDownSelectionHandler}
            />
          )}
          <img src={MenuIcon} />
        </a>
        {/** NORMAL NAV BAR */}
        <ul className="header-nav__items">
          <li className="header-nav__item">
            <a onClick={() => navClickHandler("home")}>HOME</a>
          </li>
          <li className="header-nav__item">
            <a onClick={() => navClickHandler("mission")}>ABOUT US</a>
          </li>
          <li className="header-nav__item">
            <a onClick={() => navClickHandler("apply")}>APPLY</a>
          </li>
          <li
            className="header-nav__item"
            onMouseEnter={() => setDisplayDropDown(true)}
            onMouseLeave={() => setDisplayDropDown(false)}
          >
            <a onClick={() => dropDownClickHandler()}>GET INVOLVED</a>
            {displayDropDown && (
              <DropDown
                toggleDropDown={setDisplayDropDown}
                listItemArray={getInvolvedDropDownOptions}
                selectionHandler={dropDownSelectionHandler}
              />
            )}
          </li>
        </ul>
      </nav>
      <div className="header-main__give-container">
        <a
          className={`${scrollingHeader ? "scrolling" : ""}`}
          onClick={() => navClickHandler("giving")}
        >
          GIVE
        </a>
      </div>
    </header>
  );
};

export default Header;