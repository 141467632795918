import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Home.css';
import Banner from "../common/Banner/Banner";
import HalfPhotoText from '../common/PhotoTextBlocks/HalfPhotoText';
import OneThirdPhotoText from "../common/PhotoTextBlocks/OneThirdPhotoText";
import DividerBar from '../common/Divider/DividerBar';

//import content
import videoStill from "../../assets/hh-banner-house.jpg";
import homeContent from "../../content/HomeContent.json";
import getInvolvedParagraph from "../../content/VolunteerFormParagraph.json";
import getInvolvedPhoto from "../../assets/get-involved-home.png";
import PhotoCube from '../common/PhotoTextBlocks/PhotoCube';
import ContentHeader from '../common/Content/ContentHeader';
import ContentBody from '../common/Content/ContentBody';
import PhotoTextVerticalStack from '../common/PhotoTextBlocks/PhotoTextVerticalStack';

//TODO: replace stock content
import monetaryIcon from '../../assets/Icons/saw.png';
import servicesIcon from "../../assets/Icons/dollar.png";
import timeIcon from "../../assets/Icons/clock.png";

const Home = ({ 
  onBannerClick 
}) => {
  //logic
    let navigate = useNavigate();
    const routeChange = () => {
      let path = "/nightofhope";
      navigate(path);
    };
    //SET VIDEO LINK
  const videoLink = "https://www.hopehousefl.org/media/hh-story.mp4";

    //PREPARE ONE THIRD PHOTO CUBE CONTENT
  const homePhotoCubeProps = {
    "style":"third",
    "image": React.createElement("img", {src:getInvolvedPhoto})
  }
  const homePhotoCube = React.createElement(PhotoCube, homePhotoCubeProps)
  const photoCubeArray = [homePhotoCube];
  const aboutContent = {
    "header":"Get Involved",
    "body":getInvolvedParagraph.body
  };
  const contentArray = [aboutContent];


  //download initial video
  return (
    <div className="home-content">
      <Banner onBannerClick={onBannerClick} isHome={true} isCta={false} />
      <div className="home-contnet__body">
        {/** SEASONAL GALA */}
        {/* <p className="home-content__body-large">
          <span className="home-contnet__body-announcement">Night of Hope</span>
        </p>
        <p className="home-content__body-large">
          Come and enjoy the Night of Hope Gala on October 25th, 2024 with our
          Hope House friends and family
        </p>
        <div className="home-content__button-container">
          <button className="home-content__button" onClick={routeChange}>
            Gala Information
          </button>
        </div>
        <br/>
        <DividerBar color={"coral"}/> */}
        <p className="home-content__body-large">
          {/** banner for showing that we aren't open */}
          <span className="home-contnet__body-announcement">OPEN NOW</span>
        </p>
        {/** TODO: remove this block when able */}
        <p className="home-content__body-large">
          <span className="home-contnet__body-name">HOPE HOUSE</span> exists to
          serve, equip and empower first-time, expectant moms who have chosen
          life for their babies.
        </p>
        {/** half photo text image should be image poster and video should be video */}
        <HalfPhotoText
          image={videoStill}
          header={"About Us"}
          body={homeContent.homeAboutParagraph}
          textColor={"white"}
          backgroundColor={"coral"}
          isVideo={true}
          videoAsset={videoLink}
        />
        {/** padded content */}
        <div className="home-content__padded">
          {/** volunteer one third block */}
          <OneThirdPhotoText
            textColor={"black"}
            photoCubeArray={photoCubeArray}
            contentArray={contentArray}
          />
          {/* <div className="home-content__give-block">
            <ContentHeader header="GIVE" color="black" />
            <ContentBody body="give content" color="black" /> */}
            {/** series of three photo blocks lined up */}
            {/* <div className="home-content__give-block-action-wrapper">
              <PhotoTextVerticalStack
                image={monetaryIcon}
                header={"MONETARY"}
                headerColor={"black"}
                body={homeContent.giveBlockContent.Monetary}
                bodyColor={"black"}
              />
              <PhotoTextVerticalStack
                image={servicesIcon}
                header={"SERVICES"}
                headerColor={"black"}
                body={homeContent.giveBlockContent.Services}
                bodyColor={"black"}
              />
              <PhotoTextVerticalStack
                image={timeIcon}
                header={"TIME"}
                headerColor={"black"}
                body={homeContent.giveBlockContent.Time}
                bodyColor={"black"}
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Home;