import React from 'react';
import { ColorSelection } from '../../utilities/ColorUtility';

import './DividerBar.css';


const DividerBar = ({
    color
}) => {
    return(
        <hr className={('divider-bar-default ') + (color ? ColorSelection(color): "")}/>
    )
}

export default DividerBar