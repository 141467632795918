//determine route
const determineRoute = (selectedPage) => {
    const GIVING_ROUTE = '/giving';
    const HOME_ROUTE = '/';
    const EVENTS_ROUTE = '/events';
    const VOLUNTEER_ROUTE = '/volunteer';
    const ABOUT_US_ROUTE = '/about-us';
    const APPLICATION_ROUTE = '/application';

    let route = undefined;
    switch (selectedPage.toLowerCase()) {
      case 'home':
        route =  HOME_ROUTE
        break;
      case 'mission':
        route = ABOUT_US_ROUTE
        break;
      case 'volunteer':
        route = VOLUNTEER_ROUTE;
        break;
      case 'giving':
        route = GIVING_ROUTE;
        break;
      case 'events':
        route = EVENTS_ROUTE;
        break;
      case 'apply':
        route =APPLICATION_ROUTE;
        break;
      default:
        //returns home route if error
        route = HOME_ROUTE
        break;
    }
    return route;
}

export {determineRoute};